// 事業紹介カード

.l-businessCard {
  margin-bottom: 30px;
  &:last-child {
    margin-bottom: 0;
  }
  // &__list {}
  &__item {
    &>a {
      display: block;
      transition: all $transition;
      &:hover {
        transform: translateY(-10px);
        @include mq-max(md) {
          transform: none;
        }
        .c-link--arrow {
          &:after {
            right: -10px;
          }
        }
      }
    }
  }
  &__inner {
    box-shadow: $box-shadow;
  }
  &__thumb {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    padding-top: (342 / 359 * 100%);
  }
  &__body {
    padding: 10px 22px 19px;
  }
  &__title {
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 0;
    color: $black;
    @include mq-max(md) {
      font-size: 20px;
    }
  }

  //パーツ調整
  .c-link--arrow {
    &:after {
      top: 60%;
    }
    &:hover {
      &:after {
        right: -8px;
      }
    }
  }


  //小型カード
  &--sm {
    .l-businessCard__thumb {
      width: 100%;
      padding-top: calc(249 / 260 * 100%);
    }
    .l-businessCard__title {
      font-size: 18px;
    }
    .l-businessCard__body {
      padding: 15px 22px 19px;
      border-top: 1px solid $bdrColor;
    }

    //パーツ調整
    .c-link--arrow {
      &:after {
        top: 55%;
      }
    }
  }
}