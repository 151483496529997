//リンク
.c-link {
  font-size: 12px;
  font-weight: 600;
  &--underline {
    border-bottom: 1px solid $keyColor;
  }
  &--arrow {
    display: inline-block;
    color: $keyColor;
    position: relative;
    line-height: 1;
    &:after {
      @include fa('f105');
      position: absolute;
      top: 55%;
      right: -8px;
      transform: translateY(-50%);
      line-height: 1;
      transition: all $transition;
    }
    &:hover {
      &:after {
        right: -10px;
      }
    }
  }
}