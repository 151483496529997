//ページ内アンカーリンク

.c-anchor {
  margin-bottom: 60px;
  background-color: $lightGray;
  padding: 30px 15px 25px 60px;
  @include mq-max(sm) {
    padding: 30px 5px 25px 30px;
  }
  &__item {
    display: inline-block;
    margin-right: 25px;
    margin-bottom: 10px;
    &>a {
      color: $textColor;
      font-size: 16px;
      font-weight: 400;
      display: inline-block;
      position: relative;
      &:after {
        @include fa('f107');
        position: absolute;
        top: 54%;
        right: -0.8em;
        transform: translateY(-50%);
        line-height: 1;
        color: $keyColor;
      }
    }
  }
}