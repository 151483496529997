///////////////////////////////////////////////////////////////////////////////////////////////////////

//お知らせ一覧

///////////////////////////////////////////////////////////////////////////////////////////////////////

.c-newslist {
  background-color: $lightGray;
  padding: 14px 0;
  dl {
    width: 100%;
    display: flex;
    align-items: center;
    @include mq-max(sm) {
      flex-direction: column;
      align-items: flex-start;
    }
    dt,dd {
      font-size: 18px;
      font-weight: 300;
      display: inline-block;
    }
    dt {
      display: flex;
      align-items: center;
      flex: 0 0 260px;
      max-width: 260px;
      @include mq-max(sm) {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
    dd {
      flex: 0 0 calc(100% - 260px);
      max-width: calc(100% - 260px);
      font-size: 14px;
      font-weight: 400;
      color: $black;
      @include mq-max(sm) {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }

  a {
    &[target="_blank"]{
      dd:after{
        @include fa('f35d');
        color: $keyColor;
        margin-left: 10px;
        -webkit-font-smoothing:antialiased;
      }
    }
    &[href$=".pdf"] {
      dd:after {
        @include fa('f1c1');
        font-weight: 400;
        color: $red;
        margin-left: 10px;
        -webkit-font-smoothing:antialiased;
      }
    }
  }


  &__wrap {
    position: relative;
    @include mq-max(content) {
      margin-bottom: 60px;
    }
  }
  &__toList {
    position: absolute;
    top: 50%;
    right: 35px;
    transform: translateY(-50%);
    line-height: 1;
    font-size: 12px;
    font-weight: 600;
    @include mq-max(sm) {
      top: auto;
      right: 20px;
      bottom: -25px;
      transform: none;
      font-size: 14px;
    }
    &:after {
      @include fa('f105');
      position: absolute;
      top: 55%;
      right: -9px;
      transform: translateY(-50%);
      transition: all $transition;
    }
    &:hover {
      &:after {
        right: -12px;
      }
    }
  }
  &__item {
    padding: 13px 15% 13px 53px;
    @include mq-max(sm) {
      padding: 13px 15px;
    }
    &>a {
      display: flex;
      align-items: baseline;
      opacity: 1;
      transition: opacity $transition;
      &:after {
        content: none;
      }
      &:hover {
        opacity: .7;
      }
    }
  }
  &__new {
    color: $keyColor;
    font-size: 14px;
    font-weight: 500;
    margin-right: 10px;
  }
  &__date {
    display: inline-block;
    margin-right: 18px;
    font-size: 14px;
    font-weight: 500;
    color: $textColor;
  }
  .c-label {
    font-size: 12px;
    padding: 4.5px 5.1px;
    margin-right: 14px;
    text-align: center;
    line-height: 1;
    min-width: 60px;
  }

}