//カード
.c-card {
  &__item {
    &>a {
      display: block;
      transition: all $transition;
      &:hover {
        transform: translateY(-10px);
        @include mq-max(md) {
          transform: none;
        }
        .c-card__detail {
          &:after {
            right: -20px;
          }
        }
      }
    }
  }
  &__inner {
    box-shadow: $box-shadow;
  }
  &__thumb {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    padding-top: calc(233 / 360 * 100%);
  }
  &__body {
    padding: 13px 24px 45px;
    position: relative;
    @include mq-max(sm) {
      padding: 13px 22px 45px;
    }
  }
  &__title {
    font-size: 20px;
    font-weight: 500;
    color: $keyColor;
    margin-bottom: 0;
  }
  &__desc {
    color: $textColor;
    margin: 0;
    line-height: 1.5;
  }
  &__detail {
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
    color: $keyColor;
    margin: 0;
    display: inline-block;
    position: absolute;
    bottom: 18px;
    right: 28px;
    &:after {
      @include fa('f138');
      position: absolute;
      top: 54%;
      right: -18px;
      transform: translateY(-50%);
      transition: all $transition;
    }
  }
}